/* eslint-disable jsx-a11y/heading-has-content */
import React, { useEffect, useState } from "react";
import me from "../../assets/me-squared.jpg";

import "./Hero.css";
import "../../index.css";
import Socials from "../../components/Socials/Socials";

import { useTranslation } from "react-i18next";
import TextTransition, { presets } from "react-text-transition";
import CurriculumBtn from "../../components/CurriculumBtn/CurriculumBtn";

export default function Hero() {
  const [index, setIndex] = React.useState(0);
  const { t, i18n } = useTranslation();

  const TEXTS = ["Matteo", t("developer"), t("student"), t("skier")];

  useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      3000 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  return (
    <div id="hero">
      <div class="hero__content">
        <br />
        <div class="presentation">
          <div class="presentation__left">
            <h1 className="tb">{t("welcome")}</h1>
          </div>
          <div class="presentation__right">
            <TextTransition
              className="txb bold"
              springConfig={presets.gentle}
              direction="down"
            >
              {TEXTS[index % TEXTS.length]}
            </TextTransition>
          </div>
        </div>
        <Socials />
      </div>
      <div class="hero__content">
        <div id="imageBg">
          <img src={me} alt="me" />
        </div>
      </div>
      <CurriculumBtn />
    </div>
  );
}
