import React from "react";
import { useTranslation } from "react-i18next";

import "./Contact.css";
import "../../index.css";

import check from "../../assets/icons/check-solid.svg";
import code from "../../assets/icons/code-solid.svg";
import gears from "../../assets/icons/gears-solid.svg";
import mobile from "../../assets/icons/mobile-solid.svg";
import cloud from "../../assets/icons/cloud-solid.svg";
import Socials from "../../components/Socials/Socials";
import Form from "../../components/Form/Form";

export default function Contact() {
  const { t, i18n } = useTranslation();
  return (
    <section id="contact">
      <div class="contact__content">
        <div class="contact__services">
          <div class="service">
            <img class="icon" src={code} alt="Coding" />
            <h1 class="tm">{t("createWebsites")}</h1>
            <img class="icon" src={check} alt="Yes" />
          </div>
          <div class="service">
            <img class="icon" src={gears} alt="Web App" />
            <h1 class="tm">{t("buildWebApps")}</h1>
            <img class="icon" src={check} alt="Yes" />
          </div>
          <div class="service">
            <img class="icon" src={mobile} alt="Coding" />
            <h1 class="tm">{t("devMobileApps")}</h1>
            <img class="icon" src={check} alt="Yes" />
          </div>
          <div class="service">
            <img class="icon" src={cloud} alt="Coding" />
            <h1 class="tm">{t("buildAPI")}</h1>
            <img class="icon" src={check} alt="Yes" />
          </div>
        </div>
        <Form />
      </div>
      <div class="contact__footer">
        <Socials />
        <small>Copyright 2024</small>
      </div>
    </section>
  );
}
