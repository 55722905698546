import Header from "./components/Header/Header";
import Cursor from "./components/Cursor/Cursor";
import Hero from "./views/Hero/Hero";
import About from "./views/About/About";

import { isMobile } from "react-device-detect";

import "./index.css";
import Shape from "./components/Shape/Shape";
import Menu from "./components/Menu/Menu";
import Portfolio from "./views/Portfolio/Portfolio";
import Contact from "./views/Contact/Contact";
import WorkInProgress from "./components/WorkInProgress/WorkInProgress";

function App() {
  return (
    <div>
      {isMobile ? null : <Cursor />}
      <Shape />
      <Header />
      <Menu />
      <Hero />
      <About />
      <Portfolio />
      <Contact />
    </div>
  );
}

export default App;
