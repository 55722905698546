import { wait } from "@testing-library/user-event/dist/utils";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  debug: true,
  fallbackLng: "en",
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  react: {
    bindI18n: "loaded languageChanged languageChanging",
    bindI18nStore: "added",
    useSuspense: true,
    wait: true,
  },
  // language resources
  resources: {
    en: {
      translation: {
        welcome: "Hi, I am",
        whoiam: "Who I am",
        whoIamDesc:
          "I am 18 years old, I’m born in Trieste and I have been living here since then. While I was a child, I have been playing around with my parents PC, by solving and eventually creating and solving problems, I have always been keen on that… When I was about 13 years old, I started learning Python and I assembled my first PC. A bunch of years later I got a bigger PC (server) that I used to serve my first Website that I built for my dad's shop.",
        whatido: "What I do",
        whatIdoDesc:
          "Since I built my first website, I have been learning new techniques and technologies. Now I study in the IT department at “ITS A. Volta” and I'm currently attending the 5th year of my diploma. In my spare time I'd love to train XC Skiing with my friends and obviously write code… Lots of code.",
        services: "Services",
        servicesDesc:
          "I'm able to develop Web and Mobile Applications, build delightful Websites for promoting your products or your firm. I use various technologies but I'm always open to learning new things and I will do my best to overcome your problems and light up your ideas.",
        contactMe: "Contact Me",
        location: "Triest, Italy",
        nameLabel: "Name",
        emailLabel: "Email",
        messageLabel: "Message",
        or: "Or",
        loading: "Loading...",
        submit: "Submit",
        createWebsites: "Create Websites",
        buildWebApps: "Build Web Apps",
        devMobileApps: "Develop Mobile Apps",
        buildAPI: "Build REST APIs",
        pioveDesc: "A website for a Wine Shop located in Triest.",
        khronosDesc:
          "Work in progress... A Cross-Platform Application for in-depth training analysis.",
        fillAllFields: "Please fill in all the fields.",
        invalidEmail: "The email address you entered is not valid.",
        sentSuccess: "The message was sent successfully",
        networkError:
          "Error while processing the request. Please try again later.",
        developer: "Developer",
        skier: "XC Skier",
        student: "Student",
      },
    },
    it: {
      translation: {
        welcome: "Ciao, sono",
        whoiam: "Chi sono",
        whoIamDesc:
          "Ho 18 anni e vivo a Trieste, dove sono anche nato. Fin da quando ero piccolo, ho giocato con il computer dei miei genitori, risolvendo problemi, eventualmente avendoli pure causati. Ho iniziato ad imparare Python quando avevo circa 13 anni e assemblato il mio primo computer. Qualche anno dopo ho preso un computer più grande (server) che ho usato per ospitare il mio primo sito web che ho creato per il negozio di mio padre.",
        whatido: "Cosa faccio",
        whatIdoDesc:
          "Da quando ho creato il mio primo sito web, ho sempre continuato ad imparare nuove tecniche e tecnologie. Adesso studio Informatica al “ITS A. Volta” e sto affrontando il quinto anno per ottenere il diploma di Perito Informatico. Nel mio tempo libero adoro allenare lo sci di fondo e ovviamente scrivere codice… Molto codice.",
        services: "Servizi",
        servicesDesc:
          "Sono capace di sviluppare applicazioni Web/Mobile, creare siti web accattivanti per promuovere il tuo prodotto o la tua azienda. Utilizzo varie tecnologie ma sono sempre aperto a imparare cose nuove e farò del mio meglio per risolvere i tuoi problemi e dare luce alle tue idee.",
        contactMe: "Contattami",
        location: "Trieste, Italia",
        nameLabel: "Nome",
        emailLabel: "Email",
        messageLabel: "Messaggio",
        or: "Oppure",
        loading: "Caricamento...",
        submit: "Invia",
        createWebsites: "Creazione Siti Web",
        buildWebApps: "Sviluppo Web App",
        devMobileApps: "Sviluppo Applicazioni Mobile",
        buildAPI: "Sviluppo REST APIs",
        pioveDesc: "Sito Web per un commerciante di vini con sede a Trieste.",
        khronosDesc:
          "In fase di sviluppo... App Multi-Piattaforma per analisi dati di allenamento.",
        fillAllFields: "Per favore, compila tutti i campi.",
        invalidEmail: "L'indirizzo email specificato non è valido.",
        sentSuccess: "Messaggio inviato con successo",
        networkError:
          "Errore durante l'invio del messaggio, per favore riprova più tardi.",
        developer: "Sviluppatore",
        skier: "Fondista",
        student: "Studente",
      },
    },
  },
});

export default i18n;
