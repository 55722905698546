import React, { useState, useEffect, useRef, Component } from "react";

import "./Shape.css";
import "../../index.css";

export default function Shape() {
  const [scrollPercentage, setScrollPercentage] = useState(0);
  const shapeRef = useState(null);

  const handleScroll = () => {
    const windowHeight = window.innerHeight;
    const fullHeight = document.documentElement.scrollHeight;
    const currentPosition = window.scrollY;

    const scrollPercentage =
      (currentPosition / (fullHeight - windowHeight)) * 100;

    setScrollPercentage(scrollPercentage);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div
      style={{
        "clip-path": `polygon(${
          90 - scrollPercentage
        }% 0, 100% ${scrollPercentage}%, ${100 - scrollPercentage}% 100%, ${
          55 - scrollPercentage
        }% 100%)`,
      }}
      id="shape"
    ></div>
  );
}
