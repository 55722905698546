import React from "react";

import "./About.css";
import "../../index.css";

import { useTranslation } from "react-i18next";

export default function About() {
  const { t, i18n } = useTranslation();
  return (
    <section id="about">
      <div class="about__content">
        <h1 class="tb upper bold">{t("whoiam")}</h1>
        <p class="ts">{t("whoIamDesc")}</p>
        <br />
      </div>
      <div class="about__content">
        <h1 class="tb upper bold">{t("whatido")}</h1>
        <p class="ts">{t("whatIdoDesc")}</p>
        <br />
      </div>
      <div class="about__content">
        <h1 class="tb upper bold">{t("services")}</h1>
        <p class="ts">{t("servicesDesc")}</p>
        <br />
      </div>
    </section>
  );
}
