import React from "react";

import "../../index.css";
import "./CurriculumBtn.css";

import curriculum from "../../assets/icons/curriculum.png";
import pdf from "../../assets/CV-2024_10_04.pdf";

export default function CurriculumBtn() {
  return (
    <div id="curriculumBtn" onClick={() => window.open(pdf)}>
      <img alt="myCV" src={curriculum}></img>
    </div>
  );
}
