import React from "react";
import { useState } from "react";

import "./Cursor.css";
import "../../index.css";

export default function Cursor() {
  document.body.onmousemove = function (e) {
    document.documentElement.style.setProperty("--x", e.clientX + "px");
    document.documentElement.style.setProperty("--y", e.clientY + "px");
  };

  /*document.onscroll = function () {
    //console.log(cursorY, window.scrollY, lastScrollY);
    console.log(window.scrollY - lastScrollY);
    if (lastScrollY < window.scrollY) {
      cursorY = cursorY + (window.scrollY - lastScrollY);
    } else {
      cursorY = cursorY - (window.scrollY - lastScrollY);
    }
    document.documentElement.style.setProperty("--y", cursorY + "px");
    lastScrollY = window.scrollY;
  };*/

  return <div id="invertedcursor"></div>;
}
